import gql from 'graphql-tag';

export const GET_COMPANIES = gql`
  query GET_COMPANIES(
    $where: CompanyWhereInput
    $orderBy: CompanyOrderByInput
    $first: Int
    $skip: Int
    $invitationWhere: CompanyInvitationWhereInput
    $invitationOrderBy: CompanyInvitationOrderByInput
  ) {
    companies(where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      id
      name
      logo
      type
      locked
      lockReason
      hasCompensation
      compensation
      priceWithCompensation
      users {
        id
      }
      createdAt
    }
    companyInvitations(where: $invitationWhere, orderBy: $invitationOrderBy) {
      id
      companyName
      firstName
      lastName
      email
      companyType
      inviteLink
      invitedBy {
        id
        firstName
        lastName
      }
      comment
      status
      companyCreatedAt
      createdAt
    }
  }
`;

export const COMPANY = gql`
  query COMPANY($where: CompanyWhereUniqueInput!) {
    company(where: $where) {
      id
      name
      logo
      type
      locked
      lockReason
      hasCompensation
      compensation
      priceWithCompensation
      awaitingUpgradePayment
      awaitingRenewalPayment

      users {
        id
        firstName
        lastName
        companyRole
        photo {
          uri
        }
      }

      dropPoints {
        id
        locationName
        isMicroHub
      }

      deliveryService
      deliveryRadius
      deliveryTimes {
        from
        to
        allDays
        weekday
      }
      createdAt
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UPDATE_COMPANY($data: CompanyUpdateInput!, $where: CompanyWhereUniqueInput!) {
    updateCompany(data: $data, where: $where) {
      id
    }
  }
`;

export const CONFIRM_PAYMENT = gql`
  mutation CONFIRM_PAYMENT($companyId: String!, $data: CompanyUpdateInput) {
    confirmPayment(companyId: $companyId, data: $data) {
      id
    }
  }
`;

export const INVITE_COMPANY = gql`
  mutation INVITE_COMPANY($data: CompanyInvitationCreateInput!) {
    createCompanyInvitation(data: $data) {
      id
    }
  }
`;
