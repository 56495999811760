import React from 'react';
import { useField } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';

const Input = ({ label, rightElement = null, leftElement = null, disabled = false, isDisabled = false, ...props }) => {
  // @ts-ignore
  const [field, meta] = useField(props);

  return (
    <FormControl mb="4" isInvalid={!!(meta.touched && meta.error)} isDisabled={disabled || isDisabled}>
      <FormLabel htmlFor={field.name}>{label}</FormLabel>
      <InputGroup>
        {leftElement ? <InputLeftElement pointerEvents="none" children={leftElement} color="brand.500" /> : null}
        {rightElement ? <InputRightElement pointerEvents="none" children={rightElement} color="brand.500" /> : null}
        <ChakraInput {...field} size="md" {...props} id={field.name} variant="filled" errorBorderColor="red.300" />
      </InputGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default Input;
