import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { useQuery, useMutation } from '@apollo/client';
import { Spinner, Flex, Text, Box, Badge, Button, Avatar, Grid, HStack, useColorMode } from '@chakra-ui/react';
import moment from 'moment';
import useMedia from 'use-media';
import { Link } from '@reach/router';
import get from 'lodash/get';

import Layout from '../../components/Layout';
import { COMPANY, CONFIRM_PAYMENT } from './query';
import AdvertisementCard from '../Advertisements/AdvertisementCard';
import { useUser } from '../../contexts/UserContext';
import { useSpinner } from '../../contexts/SpinnerContext';
import { useCustomToast } from '../../hooks/useCustomToast';
import { AddCompensationModal } from './AddCompensationModal';
import { ActivateDeliveryModal } from './ActivateDelivery';

interface CompanyDetailsProps extends RouteComponentProps {
  companyId?: string;
}

export const CompanyDetails: React.FC<CompanyDetailsProps> = ({ companyId }) => {
  const { colorMode } = useColorMode();
  const isMobile = useMedia({ maxWidth: '767px' });
  const { user } = useUser();
  const toast = useCustomToast();
  const { toggleSpinner } = useSpinner();
  const [confirmPayment] = useMutation(CONFIRM_PAYMENT);
  const { loading, error, refetch, data } = useQuery(COMPANY, {
    variables: {
      where: {
        id: companyId,
      },
    },
  });

  if (loading) {
    return (
      <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }
  if (error) {
    return (
      <Flex width="100%" height="300px" justifyContent="center" alignItems="center">
        Ein Fehler ist aufgetreten. Schreib Osamah an.
      </Flex>
    );
  }

  const handleInvoiceSuccess = async () => {
    toggleSpinner(true);

    try {
      await confirmPayment({
        variables: {
          companyId,
          data: {
            locked: false,
            history: {
              create: [
                {
                  type: 'UPDATED',
                  comment: `Zahlung ist eingegangen und Kunde wurde freigeschaltet`,
                  changedBy: {
                    connect: {
                      id: user.id,
                    },
                  },
                },
              ],
            },
          },
        },
      });
      await refetch();
      toast({
        title: 'Erfolg!',
        description: 'Das Unternehmen wurde erfolgreich freigeschaltet.',
        status: 'success',
      });
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }

    toggleSpinner(false);
  };

  const handleUpgradeSuccess = async () => {
    toggleSpinner(true);

    try {
      await confirmPayment({
        variables: {
          companyId,
          data: {
            awaitingUpgradePayment: false,
            history: {
              create: [
                {
                  type: 'UPDATED',
                  comment: `Upgrade-Zahlung ist eingegangen`,
                  changedBy: {
                    connect: {
                      id: user.id,
                    },
                  },
                },
              ],
            },
          },
        },
      });
      await refetch();
      toast({
        title: 'Erfolg!',
        description: 'Die Einzahlung wurde erfolgreich registriert.',
        status: 'success',
      });
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }

    toggleSpinner(false);
  };

  const handleRenewalSuccess = async () => {
    toggleSpinner(true);

    try {
      await confirmPayment({
        variables: {
          companyId,
          data: {
            awaitingRenewalPayment: false,
            history: {
              create: [
                {
                  type: 'UPDATED',
                  comment: `Renewal-Zahlung ist eingegangen`,
                  changedBy: {
                    connect: {
                      id: user.id,
                    },
                  },
                },
              ],
            },
          },
        },
      });
      await refetch();
      toast({
        title: 'Erfolg!',
        description: 'Die Einzahlung wurde erfolgreich registriert.',
        status: 'success',
      });
    } catch (error) {
      console.log(error);
      toast({
        description: get(error, 'graphQLErrors.0.message', 'Ein Fehler ist aufgetreten!'),
        status: 'error',
      });
    }

    toggleSpinner(false);
  };

  const {
    name,
    logo,
    hasCompensation,
    compensation,
    priceWithCompensation,
    type,
    createdAt,
    users,
    dropPoints,
    locked,
    lockReason,
    awaitingUpgradePayment,
    awaitingRenewalPayment,
  } = data.company;

  return (
    <Layout>
      <Box mb="8">
        <Button variant="link" onClick={() => window.history.go(-1)} size={isMobile ? 'xs' : 'sm'} mr="4">
          {'< Zur Übersicht'}
        </Button>
        <Button
          colorScheme="brand"
          onClick={() => {
            refetch();
          }}
          size={isMobile ? 'xs' : 'sm'}>
          Unternehmen Neu laden
        </Button>
      </Box>
      <Flex mb="4">
        <Avatar src={logo} mr={isMobile ? '3' : '8'} name={name} size={isMobile ? 'md' : 'xl'} />
        <Box>
          <HStack flexWrap="wrap" spacing="2" mb="1">
            <Badge colorScheme="blue">{type}</Badge>
            {hasCompensation ? <Badge colorScheme="green">{compensation} Cent Vergütung</Badge> : null}
            {hasCompensation ? <Badge colorScheme="green">{priceWithCompensation} Cent pro Buchung</Badge> : null}
          </HStack>
          <Flex>
            <Text fontSize="3xl" fontFamily="heading">
              {name}
            </Text>
          </Flex>
          <Text fontSize="sm" mb="2" color="gray.600">
            Registriert seit {moment(createdAt).format('DD.MM.YYYY, HH:mm')}
          </Text>
        </Box>
      </Flex>
      <HStack flexWrap="wrap" spacing="4" mb="8">
        {type === 'DROPPOINTS' ? <AddCompensationModal refetch={refetch} company={data?.company} /> : null}
        {type === 'DROPPOINTS' ? <ActivateDeliveryModal refetch={refetch} company={data?.company} /> : null}
        {locked && lockReason === 'AWAITING_INVOICE' ? (
          <Button size="sm" onClick={handleInvoiceSuccess}>
            Rechnung eingegangen
          </Button>
        ) : null}
        {awaitingUpgradePayment ? (
          <Button size="sm" onClick={handleUpgradeSuccess}>
            Upgrade-Zahlung eingegangen
          </Button>
        ) : null}
        {awaitingRenewalPayment ? (
          <Button size="sm" onClick={handleRenewalSuccess}>
            Erneuerungszahlung eingegangen
          </Button>
        ) : null}
      </HStack>
      <Grid gridTemplateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr', '1fr 1fr 1fr']} gap="6">
        <Flex flexDir="column">
          <Text mb="2" fontWeight="bold">
            Registrierte Mitarbeiter:
          </Text>
          {users.map(({ id, firstName, lastName, photo, companyRole }) => (
            <Box
              key={id}
              as={Link}
              //@ts-ignore
              to={`/users/${id}`}
              display="flex"
              cursor="pointer"
              width="100%"
              alignItems="center"
              p="2"
              borderRadius="4px"
              _hover={{ boxShadow: 'md', backgroundColor: colorMode === 'light' ? 'white' : 'gray.700' }}>
              <Avatar src={photo?.uri} mr="4" />
              <Box width="80px">
                <Badge mr="2">{companyRole}</Badge>
              </Box>
              <Box flex="1">
                {firstName} {lastName}
              </Box>
            </Box>
          ))}
        </Flex>
        {dropPoints.length > 0 ? (
          <Flex flexDir="column" gap="4">
            <Text fontWeight="bold">DropPoints:</Text>
            {dropPoints.map((dropPoint) => (
              <Box
                key={dropPoint.id}
                width="100%"
                rounded="md"
                borderWidth="1px"
                py="4"
                px="6"
                _hover={{ boxShadow: 'md', backgroundColor: colorMode === 'light' ? 'white' : 'gray.700' }}>
                {dropPoint.isMicroHub ? <Badge colorScheme="blue">Microhub</Badge> : null}
                <Text fontWeight="bold">{dropPoint.locationName}</Text>
                <Text>{dropPoint.id}</Text>
              </Box>
            ))}
          </Flex>
        ) : null}
      </Grid>
    </Layout>
  );
};
